@charset "UTF-8";
/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block UI.
 */
/**
 * Border radii.
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Focus styles.
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.editor-autocompleters__user .editor-autocompleters__no-avatar::before {
  /* stylelint-disable */
  font: normal 20px/1 dashicons;
  /* stylelint-enable */
  content: "\f110";
  margin-right: 5px;
  vertical-align: middle; }

.editor-autocompleters__user .editor-autocompleters__user-avatar {
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: none;
  width: 24px;
  height: 24px; }

.editor-autocompleters__user .editor-autocompleters__user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  flex-shrink: 0;
  flex-grow: 1; }

.editor-autocompleters__user .editor-autocompleters__user-slug {
  margin-left: 8px;
  color: #8f98a1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: none;
  max-width: 100px;
  flex-grow: 0;
  flex-shrink: 0; }

.editor-autocompleters__user:hover .editor-autocompleters__user-slug {
  color: #66c6e4; }

.document-outline {
  margin: 20px 0; }
  .document-outline ul {
    margin: 0;
    padding: 0; }

.document-outline__item {
  display: flex;
  margin: 4px 0; }
  .document-outline__item a {
    text-decoration: none; }
  .document-outline__item .document-outline__emdash::before {
    color: #e2e4e7;
    margin-right: 4px; }
  .document-outline__item.is-h2 .document-outline__emdash::before {
    content: "—"; }
  .document-outline__item.is-h3 .document-outline__emdash::before {
    content: "——"; }
  .document-outline__item.is-h4 .document-outline__emdash::before {
    content: "———"; }
  .document-outline__item.is-h5 .document-outline__emdash::before {
    content: "————"; }
  .document-outline__item.is-h6 .document-outline__emdash::before {
    content: "—————"; }

.document-outline__button {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: flex-start;
  margin: 0 0 0 -1px;
  padding: 2px 5px 2px 1px;
  color: #23282d;
  text-align: left;
  border-radius: 2px; }
  .document-outline__button:disabled {
    cursor: default; }
  .document-outline__button:focus {
    box-shadow: 0 0 0 1.5px #007cba;
    outline: 2px solid transparent; }
  body.admin-color-sunrise .document-outline__button:focus{
    box-shadow: 0 0 0 1.5px #d1864a; }
  body.admin-color-ocean .document-outline__button:focus{
    box-shadow: 0 0 0 1.5px #a3b9a2; }
  body.admin-color-midnight .document-outline__button:focus{
    box-shadow: 0 0 0 1.5px #e14d43; }
  body.admin-color-ectoplasm .document-outline__button:focus{
    box-shadow: 0 0 0 1.5px #a7b656; }
  body.admin-color-coffee .document-outline__button:focus{
    box-shadow: 0 0 0 1.5px #c2a68c; }
  body.admin-color-blue .document-outline__button:focus{
    box-shadow: 0 0 0 1.5px #d9ab59; }
  body.admin-color-light .document-outline__button:focus{
    box-shadow: 0 0 0 1.5px #0085ba; }

.document-outline__level {
  background: #e2e4e7;
  color: #23282d;
  border-radius: 3px;
  font-size: 13px;
  padding: 1px 6px;
  margin-right: 4px; }
  .is-invalid .document-outline__level {
    background: #f0b849; }

.document-outline__item-content {
  padding: 1px 0; }

.components-editor-notices__dismissible {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  color: #191e23; }

.components-editor-notices__pinned {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  color: #191e23; }

.components-editor-notices__dismissible .components-notice,
.components-editor-notices__pinned .components-notice {
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 12px;
  min-height: 60px; }
  .components-editor-notices__dismissible .components-notice .components-notice__dismiss,
  .components-editor-notices__pinned .components-notice .components-notice__dismiss {
    margin-top: 12px; }

.components-editor-notices__snackbar {
  width: 100%; }
  @media (min-width: 782px) {
    .components-editor-notices__snackbar {
      width: fit-content;
      width: -moz-fit-content; } }

.entities-saved-states__panel {
  box-sizing: border-box;
  background: #fff;
  position: fixed;
  z-index: 100001;
  top: 46px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  box-sizing: border-box; }
  .entities-saved-states__panel *,
  .entities-saved-states__panel *::before,
  .entities-saved-states__panel *::after {
    box-sizing: inherit; }
  .entities-saved-states__panel .input-control,
  .entities-saved-states__panel input[type="text"],
  .entities-saved-states__panel input[type="search"],
  .entities-saved-states__panel input[type="radio"],
  .entities-saved-states__panel input[type="tel"],
  .entities-saved-states__panel input[type="time"],
  .entities-saved-states__panel input[type="url"],
  .entities-saved-states__panel input[type="week"],
  .entities-saved-states__panel input[type="password"],
  .entities-saved-states__panel input[type="checkbox"],
  .entities-saved-states__panel input[type="color"],
  .entities-saved-states__panel input[type="date"],
  .entities-saved-states__panel input[type="datetime"],
  .entities-saved-states__panel input[type="datetime-local"],
  .entities-saved-states__panel input[type="email"],
  .entities-saved-states__panel input[type="month"],
  .entities-saved-states__panel input[type="number"],
  .entities-saved-states__panel select,
  .entities-saved-states__panel textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    padding: 6px 8px;
    box-shadow: 0 0 0 transparent;
    transition: box-shadow 0.1s linear;
    border-radius: 2px;
    border: 1px solid #757575;
    /* Fonts smaller than 16px causes mobile safari to zoom. */
    font-size: 16px;
    /* Override core line-height. To be reviewed. */
    line-height: normal; }
    @media (prefers-reduced-motion: reduce) {
      .entities-saved-states__panel .input-control,
      .entities-saved-states__panel input[type="text"],
      .entities-saved-states__panel input[type="search"],
      .entities-saved-states__panel input[type="radio"],
      .entities-saved-states__panel input[type="tel"],
      .entities-saved-states__panel input[type="time"],
      .entities-saved-states__panel input[type="url"],
      .entities-saved-states__panel input[type="week"],
      .entities-saved-states__panel input[type="password"],
      .entities-saved-states__panel input[type="checkbox"],
      .entities-saved-states__panel input[type="color"],
      .entities-saved-states__panel input[type="date"],
      .entities-saved-states__panel input[type="datetime"],
      .entities-saved-states__panel input[type="datetime-local"],
      .entities-saved-states__panel input[type="email"],
      .entities-saved-states__panel input[type="month"],
      .entities-saved-states__panel input[type="number"],
      .entities-saved-states__panel select,
      .entities-saved-states__panel textarea {
        transition-duration: 0s; } }
    @media (min-width: 600px) {
      .entities-saved-states__panel .input-control,
      .entities-saved-states__panel input[type="text"],
      .entities-saved-states__panel input[type="search"],
      .entities-saved-states__panel input[type="radio"],
      .entities-saved-states__panel input[type="tel"],
      .entities-saved-states__panel input[type="time"],
      .entities-saved-states__panel input[type="url"],
      .entities-saved-states__panel input[type="week"],
      .entities-saved-states__panel input[type="password"],
      .entities-saved-states__panel input[type="checkbox"],
      .entities-saved-states__panel input[type="color"],
      .entities-saved-states__panel input[type="date"],
      .entities-saved-states__panel input[type="datetime"],
      .entities-saved-states__panel input[type="datetime-local"],
      .entities-saved-states__panel input[type="email"],
      .entities-saved-states__panel input[type="month"],
      .entities-saved-states__panel input[type="number"],
      .entities-saved-states__panel select,
      .entities-saved-states__panel textarea {
        font-size: 13px;
        /* Override core line-height. To be reviewed. */
        line-height: normal; } }
    .entities-saved-states__panel .input-control:focus,
    .entities-saved-states__panel input[type="text"]:focus,
    .entities-saved-states__panel input[type="search"]:focus,
    .entities-saved-states__panel input[type="radio"]:focus,
    .entities-saved-states__panel input[type="tel"]:focus,
    .entities-saved-states__panel input[type="time"]:focus,
    .entities-saved-states__panel input[type="url"]:focus,
    .entities-saved-states__panel input[type="week"]:focus,
    .entities-saved-states__panel input[type="password"]:focus,
    .entities-saved-states__panel input[type="checkbox"]:focus,
    .entities-saved-states__panel input[type="color"]:focus,
    .entities-saved-states__panel input[type="date"]:focus,
    .entities-saved-states__panel input[type="datetime"]:focus,
    .entities-saved-states__panel input[type="datetime-local"]:focus,
    .entities-saved-states__panel input[type="email"]:focus,
    .entities-saved-states__panel input[type="month"]:focus,
    .entities-saved-states__panel input[type="number"]:focus,
    .entities-saved-states__panel select:focus,
    .entities-saved-states__panel textarea:focus {
      border-color: rgb(0, 124, 186);
      box-shadow: 0 0 0 0.5px #007cba;
      outline: 2px solid transparent; }
    body.admin-color-sunrise .entities-saved-states__panel .input-control:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="text"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="search"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="radio"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="tel"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="time"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="url"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="week"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="password"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="checkbox"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="color"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="date"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="datetime"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="datetime-local"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="email"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="month"]:focus, body.admin-color-sunrise .entities-saved-states__panel input[type="number"]:focus, body.admin-color-sunrise .entities-saved-states__panel select:focus, body.admin-color-sunrise .entities-saved-states__panel textarea:focus{
      border-color: rgb(209, 134, 74);
      box-shadow: 0 0 0 0.5px #d1864a; }
    body.admin-color-ocean .entities-saved-states__panel .input-control:focus, body.admin-color-ocean .entities-saved-states__panel input[type="text"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="search"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="radio"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="tel"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="time"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="url"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="week"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="password"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="checkbox"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="color"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="date"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="datetime"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="datetime-local"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="email"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="month"]:focus, body.admin-color-ocean .entities-saved-states__panel input[type="number"]:focus, body.admin-color-ocean .entities-saved-states__panel select:focus, body.admin-color-ocean .entities-saved-states__panel textarea:focus{
      border-color: rgb(163, 185, 162);
      box-shadow: 0 0 0 0.5px #a3b9a2; }
    body.admin-color-midnight .entities-saved-states__panel .input-control:focus, body.admin-color-midnight .entities-saved-states__panel input[type="text"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="search"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="radio"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="tel"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="time"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="url"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="week"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="password"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="checkbox"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="color"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="date"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="datetime"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="datetime-local"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="email"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="month"]:focus, body.admin-color-midnight .entities-saved-states__panel input[type="number"]:focus, body.admin-color-midnight .entities-saved-states__panel select:focus, body.admin-color-midnight .entities-saved-states__panel textarea:focus{
      border-color: rgb(225, 77, 67);
      box-shadow: 0 0 0 0.5px #e14d43; }
    body.admin-color-ectoplasm .entities-saved-states__panel .input-control:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="text"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="search"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="radio"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="tel"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="time"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="url"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="week"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="password"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="checkbox"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="color"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="date"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="datetime"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="datetime-local"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="email"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="month"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel input[type="number"]:focus, body.admin-color-ectoplasm .entities-saved-states__panel select:focus, body.admin-color-ectoplasm .entities-saved-states__panel textarea:focus{
      border-color: rgb(167, 182, 86);
      box-shadow: 0 0 0 0.5px #a7b656; }
    body.admin-color-coffee .entities-saved-states__panel .input-control:focus, body.admin-color-coffee .entities-saved-states__panel input[type="text"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="search"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="radio"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="tel"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="time"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="url"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="week"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="password"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="checkbox"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="color"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="date"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="datetime"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="datetime-local"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="email"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="month"]:focus, body.admin-color-coffee .entities-saved-states__panel input[type="number"]:focus, body.admin-color-coffee .entities-saved-states__panel select:focus, body.admin-color-coffee .entities-saved-states__panel textarea:focus{
      border-color: rgb(194, 166, 140);
      box-shadow: 0 0 0 0.5px #c2a68c; }
    body.admin-color-blue .entities-saved-states__panel .input-control:focus, body.admin-color-blue .entities-saved-states__panel input[type="text"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="search"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="radio"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="tel"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="time"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="url"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="week"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="password"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="checkbox"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="color"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="date"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="datetime"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="datetime-local"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="email"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="month"]:focus, body.admin-color-blue .entities-saved-states__panel input[type="number"]:focus, body.admin-color-blue .entities-saved-states__panel select:focus, body.admin-color-blue .entities-saved-states__panel textarea:focus{
      border-color: rgb(217, 171, 89);
      box-shadow: 0 0 0 0.5px #d9ab59; }
    body.admin-color-light .entities-saved-states__panel .input-control:focus, body.admin-color-light .entities-saved-states__panel input[type="text"]:focus, body.admin-color-light .entities-saved-states__panel input[type="search"]:focus, body.admin-color-light .entities-saved-states__panel input[type="radio"]:focus, body.admin-color-light .entities-saved-states__panel input[type="tel"]:focus, body.admin-color-light .entities-saved-states__panel input[type="time"]:focus, body.admin-color-light .entities-saved-states__panel input[type="url"]:focus, body.admin-color-light .entities-saved-states__panel input[type="week"]:focus, body.admin-color-light .entities-saved-states__panel input[type="password"]:focus, body.admin-color-light .entities-saved-states__panel input[type="checkbox"]:focus, body.admin-color-light .entities-saved-states__panel input[type="color"]:focus, body.admin-color-light .entities-saved-states__panel input[type="date"]:focus, body.admin-color-light .entities-saved-states__panel input[type="datetime"]:focus, body.admin-color-light .entities-saved-states__panel input[type="datetime-local"]:focus, body.admin-color-light .entities-saved-states__panel input[type="email"]:focus, body.admin-color-light .entities-saved-states__panel input[type="month"]:focus, body.admin-color-light .entities-saved-states__panel input[type="number"]:focus, body.admin-color-light .entities-saved-states__panel select:focus, body.admin-color-light .entities-saved-states__panel textarea:focus{
      border-color: rgb(0, 133, 186);
      box-shadow: 0 0 0 0.5px #0085ba; }
  .entities-saved-states__panel input[type="number"] {
    padding-left: 4px;
    padding-right: 4px; }
  .entities-saved-states__panel select {
    padding: 3px 24px 3px 8px;
    font-size: 13px;
    color: #555d66; }
    .entities-saved-states__panel select:focus {
      border-color: #008dbe;
      outline: 2px solid transparent;
      outline-offset: 0; }
  .entities-saved-states__panel input[type="checkbox"],
  .entities-saved-states__panel input[type="radio"] {
    border: 2px solid #757575;
    margin-right: 12px;
    transition: none; }
    .entities-saved-states__panel input[type="checkbox"]:focus,
    .entities-saved-states__panel input[type="radio"]:focus {
      border-color: #757575;
      box-shadow: 0 0 0 1px #757575; }
    .entities-saved-states__panel input[type="checkbox"]:checked,
    .entities-saved-states__panel input[type="radio"]:checked {
      background: #11a0d2;
      border-color: #11a0d2; }
    body.admin-color-sunrise .entities-saved-states__panel input[type="checkbox"]:checked, body.admin-color-sunrise .entities-saved-states__panel input[type="radio"]:checked{
      background: #c8b03c;
      border-color: #c8b03c; }
    body.admin-color-ocean .entities-saved-states__panel input[type="checkbox"]:checked, body.admin-color-ocean .entities-saved-states__panel input[type="radio"]:checked{
      background: #a3b9a2;
      border-color: #a3b9a2; }
    body.admin-color-midnight .entities-saved-states__panel input[type="checkbox"]:checked, body.admin-color-midnight .entities-saved-states__panel input[type="radio"]:checked{
      background: #77a6b9;
      border-color: #77a6b9; }
    body.admin-color-ectoplasm .entities-saved-states__panel input[type="checkbox"]:checked, body.admin-color-ectoplasm .entities-saved-states__panel input[type="radio"]:checked{
      background: #a7b656;
      border-color: #a7b656; }
    body.admin-color-coffee .entities-saved-states__panel input[type="checkbox"]:checked, body.admin-color-coffee .entities-saved-states__panel input[type="radio"]:checked{
      background: #c2a68c;
      border-color: #c2a68c; }
    body.admin-color-blue .entities-saved-states__panel input[type="checkbox"]:checked, body.admin-color-blue .entities-saved-states__panel input[type="radio"]:checked{
      background: #82b4cb;
      border-color: #82b4cb; }
    body.admin-color-light .entities-saved-states__panel input[type="checkbox"]:checked, body.admin-color-light .entities-saved-states__panel input[type="radio"]:checked{
      background: #11a0d2;
      border-color: #11a0d2; }
    .entities-saved-states__panel input[type="checkbox"]:checked:focus,
    .entities-saved-states__panel input[type="radio"]:checked:focus {
      box-shadow: 0 0 0 1.5px #757575; }
  .entities-saved-states__panel input[type="checkbox"] {
    border-radius: 2px; }
    .entities-saved-states__panel input[type="checkbox"]:checked::before, .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]::before {
      margin: -3px -5px;
      color: #fff; }
      @media (min-width: 782px) {
        .entities-saved-states__panel input[type="checkbox"]:checked::before, .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]::before {
          margin: -4px 0 0 -5px; } }
    .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"] {
      background: #11a0d2;
      border-color: #11a0d2; }
    body.admin-color-sunrise .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]{
      background: #c8b03c;
      border-color: #c8b03c; }
    body.admin-color-ocean .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]{
      background: #a3b9a2;
      border-color: #a3b9a2; }
    body.admin-color-midnight .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]{
      background: #77a6b9;
      border-color: #77a6b9; }
    body.admin-color-ectoplasm .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]{
      background: #a7b656;
      border-color: #a7b656; }
    body.admin-color-coffee .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]{
      background: #c2a68c;
      border-color: #c2a68c; }
    body.admin-color-blue .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]{
      background: #82b4cb;
      border-color: #82b4cb; }
    body.admin-color-light .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]{
      background: #11a0d2;
      border-color: #11a0d2; }
      .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]::before {
        content: "\f460";
        float: left;
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        /* stylelint-disable */
        font: normal 30px/1 dashicons;
        /* stylelint-enable */
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
        @media (min-width: 782px) {
          .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]::before {
            float: none;
            font-size: 21px; } }
      .entities-saved-states__panel input[type="checkbox"][aria-checked="mixed"]:focus {
        box-shadow: 0 0 0 1.5px #555d66; }
  .entities-saved-states__panel input[type="radio"] {
    border-radius: 50%; }
    .entities-saved-states__panel input[type="radio"]:checked::before {
      width: 6px;
      height: 6px;
      margin: 6px 0 0 6px;
      background-color: #fff; }
      @media (min-width: 782px) {
        .entities-saved-states__panel input[type="radio"]:checked::before {
          margin: 3px 0 0 3px; } }
  .entities-saved-states__panel input::-webkit-input-placeholder,
  .entities-saved-states__panel textarea::-webkit-input-placeholder {
    color: rgba(14, 28, 46, 0.62); }
  .entities-saved-states__panel input::-moz-placeholder,
  .entities-saved-states__panel textarea::-moz-placeholder {
    opacity: 1;
    color: rgba(14, 28, 46, 0.62); }
  .entities-saved-states__panel input:-ms-input-placeholder,
  .entities-saved-states__panel textarea:-ms-input-placeholder {
    color: rgba(14, 28, 46, 0.62); }
  .is-dark-theme .entities-saved-states__panel input::-webkit-input-placeholder, .is-dark-theme
  .entities-saved-states__panel textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.65); }
  .is-dark-theme .entities-saved-states__panel input::-moz-placeholder, .is-dark-theme
  .entities-saved-states__panel textarea::-moz-placeholder {
    opacity: 1;
    color: rgba(255, 255, 255, 0.65); }
  .is-dark-theme .entities-saved-states__panel input:-ms-input-placeholder, .is-dark-theme
  .entities-saved-states__panel textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.65); }
  .entities-saved-states__panel .entities-saved-states__find-entity {
    display: none; }
  .entities-saved-states__panel .entities-saved-states__find-entity-small {
    display: block; }
  @media (min-width: 782px) {
    .entities-saved-states__panel {
      z-index: 99998;
      top: 32px;
      left: auto;
      width: 280px;
      border-left: 1px solid #e2e4e7; }
      body.is-fullscreen-mode .entities-saved-states__panel {
        top: 0; }
      .entities-saved-states__panel .entities-saved-states__find-entity {
        display: block; }
      .entities-saved-states__panel .entities-saved-states__find-entity-small {
        display: none; } }
  .entities-saved-states__panel .entities-saved-states__panel-header {
    background: #fff;
    padding-left: 8px;
    padding-right: 8px;
    height: 61px;
    border-bottom: 1px solid #e2e4e7;
    display: flex;
    align-items: center;
    align-content: space-between; }
    .entities-saved-states__panel .entities-saved-states__panel-header .components-button.has-icon {
      position: absolute;
      right: 8px; }
  .entities-saved-states__panel .entities-saved-states__text-prompt {
    border-bottom: 1px solid #e2e4e7; }
    .entities-saved-states__panel .entities-saved-states__text-prompt h2 {
      padding: 16px;
      padding-bottom: 12px;
      margin: 0;
      font-size: 18px; }
  .entities-saved-states__panel .editor-entities-saved-states__save-button {
    display: block;
    margin: 12px auto; }

.editor-error-boundary {
  margin: auto;
  max-width: 780px;
  padding: 20px;
  margin-top: 60px;
  box-shadow: 0 3px 30px rgba(25, 30, 35, 0.2); }

.editor-page-attributes__template {
  margin-bottom: 10px; }
  .editor-page-attributes__template label,
  .editor-page-attributes__template select {
    width: 100%; }

.editor-page-attributes__order {
  width: 100%; }
  .editor-page-attributes__order .components-base-control__field {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .editor-page-attributes__order input {
    width: 66px; }

.editor-post-excerpt__textarea {
  width: 100%;
  margin-bottom: 10px; }

.editor-post-featured-image {
  padding: 0; }
  .editor-post-featured-image__container {
    margin-bottom: 1em;
    position: relative; }
  .editor-post-featured-image .components-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -9px; }
  .editor-post-featured-image .components-button + .components-button {
    display: block;
    margin-top: 1em; }
  .editor-post-featured-image .components-responsive-wrapper__content {
    max-width: 100%;
    width: auto; }

.editor-post-featured-image__toggle,
.editor-post-featured-image__preview {
  display: block;
  width: 100%;
  padding: 0;
  transition: all 0.1s ease-out;
  box-shadow: 0 0 0 0 #00a0d2; }
  @media (prefers-reduced-motion: reduce) {
    .editor-post-featured-image__toggle,
    .editor-post-featured-image__preview {
      transition-duration: 0s; } }

.editor-post-featured-image__preview {
  height: auto; }

.editor-post-featured-image__preview:not(:disabled):not([aria-disabled="true"]):focus {
  box-shadow: 0 0 0 4px #00a0d2; }

.editor-post-featured-image__toggle {
  border: 1px dashed #a2aab2;
  background-color: #edeff0;
  min-height: 90px;
  line-height: 20px;
  padding: 8px 0;
  text-align: center; }
  .editor-post-featured-image__toggle:hover {
    background-color: #f8f9f9; }

.editor-post-format {
  flex-direction: column;
  align-items: stretch;
  width: 100%; }

.editor-post-format__content {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.editor-post-format__suggestion {
  text-align: right;
  font-size: 13px; }

.editor-post-last-revision__title {
  width: 100%;
  font-weight: 600; }
  .editor-post-last-revision__title .dashicon {
    margin-right: 5px; }

.components-button.editor-post-last-revision__title {
  height: auto; }
  .components-button.editor-post-last-revision__title:hover, .components-button.editor-post-last-revision__title:active {
    background: #f3f4f5; }
  .components-button.editor-post-last-revision__title:focus {
    box-shadow: inset 0 0 0 1.5px #007cba;
    border-radius: 0; }
  body.admin-color-sunrise .components-button.editor-post-last-revision__title:focus{
    box-shadow: inset 0 0 0 1.5px #d1864a; }
  body.admin-color-ocean .components-button.editor-post-last-revision__title:focus{
    box-shadow: inset 0 0 0 1.5px #a3b9a2; }
  body.admin-color-midnight .components-button.editor-post-last-revision__title:focus{
    box-shadow: inset 0 0 0 1.5px #e14d43; }
  body.admin-color-ectoplasm .components-button.editor-post-last-revision__title:focus{
    box-shadow: inset 0 0 0 1.5px #a7b656; }
  body.admin-color-coffee .components-button.editor-post-last-revision__title:focus{
    box-shadow: inset 0 0 0 1.5px #c2a68c; }
  body.admin-color-blue .components-button.editor-post-last-revision__title:focus{
    box-shadow: inset 0 0 0 1.5px #d9ab59; }
  body.admin-color-light .components-button.editor-post-last-revision__title:focus{
    box-shadow: inset 0 0 0 1.5px #0085ba; }

.editor-post-locked-modal {
  height: auto;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  max-width: 480px; }
  .editor-post-locked-modal .components-modal__header {
    height: 36px; }
  .editor-post-locked-modal .components-modal__content {
    height: auto; }

.editor-post-locked-modal__buttons {
  margin-top: 10px; }
  .editor-post-locked-modal__buttons .components-button {
    margin-right: 5px; }

.editor-post-locked-modal__avatar {
  float: left;
  margin: 5px;
  margin-right: 15px; }

.editor-post-publish-button__button.has-changes-dot::before {
  background: currentcolor;
  border-radius: 4px;
  content: "";
  height: 8px;
  margin: auto 5px auto -3px;
  width: 8px; }

.editor-post-publish-panel {
  background: #fff; }

.editor-post-publish-panel__content {
  min-height: calc(100% - 144px); }
  .editor-post-publish-panel__content .components-spinner {
    display: block;
    margin: 100px auto 0; }

.editor-post-publish-panel__header {
  background: #fff;
  padding-left: 8px;
  padding-right: 8px;
  height: 61px;
  border-bottom: 1px solid #e2e4e7;
  display: flex;
  align-items: center;
  align-content: space-between; }
  .editor-post-publish-panel__header .components-button.has-icon {
    position: absolute;
    right: 8px; }

.editor-post-publish-panel__header-publish-button {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  text-align: right;
  flex-wrap: nowrap; }

.editor-post-publish-panel__header-published {
  flex-grow: 1; }

.editor-post-publish-panel__footer {
  padding: 16px; }

.components-button.editor-post-publish-panel__toggle.is-primary {
  display: inline-flex;
  align-items: center; }
  .components-button.editor-post-publish-panel__toggle.is-primary.is-busy .dashicon {
    display: none; }
  .components-button.editor-post-publish-panel__toggle.is-primary .dashicon {
    margin-right: -4px; }

.editor-post-publish-panel__link {
  font-weight: 400;
  padding-left: 4px; }

.editor-post-publish-panel__prepublish {
  padding: 16px; }
  .editor-post-publish-panel__prepublish strong {
    color: #191e23; }
  .editor-post-publish-panel__prepublish .components-panel__body {
    background: #fff;
    margin-left: -16px;
    margin-right: -16px; }
  .editor-post-publish-panel__prepublish .editor-post-visibility__dialog-legend {
    display: none; }

.post-publish-panel__postpublish .components-panel__body {
  border-bottom: 1px solid #e2e4e7;
  border-top: none; }

.post-publish-panel__postpublish-buttons {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  margin: -5px; }
  .post-publish-panel__postpublish-buttons > * {
    flex-grow: 1;
    margin: 5px; }
  .post-publish-panel__postpublish-buttons .components-button {
    height: auto;
    justify-content: center;
    padding: 3px 10px 4px;
    line-height: 1.6;
    text-align: center;
    white-space: normal; }
  .post-publish-panel__postpublish-buttons .components-clipboard-button {
    width: 100%; }

.post-publish-panel__postpublish-post-address {
  margin-bottom: 16px; }
  .post-publish-panel__postpublish-post-address input[readonly] {
    padding: 10px;
    background: #e8eaeb;
    overflow: hidden;
    text-overflow: ellipsis; }

.post-publish-panel__postpublish-header {
  font-weight: 500; }

.post-publish-panel__postpublish-subheader {
  margin: 0 0 8px; }

.post-publish-panel__tip {
  color: #f0b849; }

.editor-post-saved-state {
  display: flex;
  align-items: center;
  width: 28px;
  padding: 12px 4px;
  color: #757575;
  overflow: hidden;
  white-space: nowrap; }
  .editor-post-saved-state svg {
    display: inline-block;
    flex: 0 0 auto;
    fill: currentColor;
    margin-right: 8px; }
  @media (min-width: 600px) {
    .editor-post-saved-state {
      width: auto;
      padding: 8px 12px;
      text-indent: inherit; }
      .editor-post-saved-state svg {
        margin-right: 4px; } }

.editor-post-taxonomies__hierarchical-terms-list {
  max-height: 14em;
  overflow: auto;
  padding-left: 2px; }

.editor-post-taxonomies__hierarchical-terms-choice {
  margin-bottom: 8px; }

.editor-post-taxonomies__hierarchical-terms-subchoices {
  margin-top: 8px;
  margin-left: 16px; }

.components-button.editor-post-taxonomies__hierarchical-terms-submit,
.components-button.editor-post-taxonomies__hierarchical-terms-add {
  margin-top: 12px; }

.editor-post-taxonomies__hierarchical-terms-label {
  display: inline-block;
  margin-top: 12px; }

.editor-post-taxonomies__hierarchical-terms-input {
  margin-top: 8px;
  width: 100%; }

.editor-post-taxonomies__hierarchical-terms-filter {
  margin-bottom: 8px;
  width: 100%; }

.edit-post-text-editor__body textarea.editor-post-text-editor {
  border: 1px solid #ccc;
  display: block;
  margin: 0;
  width: 100%;
  box-shadow: none;
  resize: none;
  overflow: hidden;
  font-family: Menlo, Consolas, monaco, monospace;
  line-height: 150%;
  border-radius: 0;
  padding: 16px;
  min-height: 200px;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px !important; }
  @media (min-width: 600px) {
    .edit-post-text-editor__body textarea.editor-post-text-editor {
      font-size: 14px !important; } }
  .edit-post-text-editor__body textarea.editor-post-text-editor:focus {
    border: 1px solid #1e1e1e;
    box-shadow: none;
    position: relative; }

.edit-post-post-visibility__dialog,
.editor-post-visibility__dialog-fieldset {
  padding: 4px;
  padding-top: 0; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-legend,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-legend {
    font-weight: 600;
    margin-bottom: 1em;
    margin-top: 0.5em;
    padding: 0; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio {
    margin-top: 2px; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-label,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-label {
    font-weight: 600; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-info,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-info {
    margin-top: 0;
    margin-left: 28px; }
  .edit-post-post-visibility__dialog .editor-post-visibility__choice:last-child .editor-post-visibility__dialog-info,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__choice:last-child .editor-post-visibility__dialog-info {
    margin-bottom: 0; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-password-input,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-password-input {
    margin-left: 28px; }

.editor-post-title {
  position: relative; }
  .editor-post-title .editor-post-title__input {
    display: block;
    width: 100%;
    margin: 0;
    box-shadow: none;
    background: transparent;
    transition: border 0.1s ease-out, box-shadow 0.1s linear;
    padding: 19px 0;
    word-break: keep-all;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    outline: 1px solid transparent;
    font-size: 2.44em;
    font-weight: bold; }
    @media (prefers-reduced-motion: reduce) {
      .editor-post-title .editor-post-title__input {
        transition-duration: 0s; } }
    @media (min-width: 600px) {
      .editor-post-title .editor-post-title__input {
        border-width: 1px; } }
    .editor-post-title .editor-post-title__input::-webkit-input-placeholder {
      color: rgba(22, 36, 53, 0.55); }
    .editor-post-title .editor-post-title__input::-moz-placeholder {
      color: rgba(22, 36, 53, 0.55); }
    .editor-post-title .editor-post-title__input:-ms-input-placeholder {
      color: rgba(22, 36, 53, 0.55); }
    .editor-post-title .editor-post-title__input:focus {
      border: 1px solid transparent;
      outline: 1px solid transparent;
      box-shadow: none; }
  .editor-post-title.is-focus-mode .editor-post-title__input {
    opacity: 0.5;
    transition: opacity 0.1s linear; }
    @media (prefers-reduced-motion: reduce) {
      .editor-post-title.is-focus-mode .editor-post-title__input {
        transition-duration: 0s; } }
    .editor-post-title.is-focus-mode .editor-post-title__input:focus {
      opacity: 1; }

.editor-post-trash.components-button {
  color: #b52727;
  border-color: #b52727;
  justify-content: center; }
  .editor-post-trash.components-button:not(:disabled):not([aria-disabled="true"]):hover, .editor-post-trash.components-button:not([aria-disabled="true"]):focus {
    color: #a02222;
    border-color: #a02222; }

.table-of-contents__popover.components-popover .components-popover__content {
  min-width: 380px; }

.components-popover.table-of-contents__popover {
  z-index: 99998; }

.table-of-contents__popover .components-popover__content {
  padding: 16px; }
  @media (min-width: 600px) {
    .table-of-contents__popover .components-popover__content {
      max-height: calc(100vh - 120px);
      overflow-y: auto; } }

.table-of-contents__popover hr {
  margin: 10px -16px 0; }

.table-of-contents__wrapper:focus::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 1.5px #007cba; }

body.admin-color-sunrise .table-of-contents__wrapper:focus::before{
  box-shadow: inset 0 0 0 1.5px #d1864a; }

body.admin-color-ocean .table-of-contents__wrapper:focus::before{
  box-shadow: inset 0 0 0 1.5px #a3b9a2; }

body.admin-color-midnight .table-of-contents__wrapper:focus::before{
  box-shadow: inset 0 0 0 1.5px #e14d43; }

body.admin-color-ectoplasm .table-of-contents__wrapper:focus::before{
  box-shadow: inset 0 0 0 1.5px #a7b656; }

body.admin-color-coffee .table-of-contents__wrapper:focus::before{
  box-shadow: inset 0 0 0 1.5px #c2a68c; }

body.admin-color-blue .table-of-contents__wrapper:focus::before{
  box-shadow: inset 0 0 0 1.5px #d9ab59; }

body.admin-color-light .table-of-contents__wrapper:focus::before{
  box-shadow: inset 0 0 0 1.5px #0085ba; }

.table-of-contents__counts {
  display: flex;
  flex-wrap: wrap;
  margin: 0; }

.table-of-contents__count {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: #6c7781;
  padding-right: 8px;
  margin-bottom: 0; }
  .table-of-contents__count:last-child {
    padding-right: 0; }

.table-of-contents__number,
.table-of-contents__popover .word-count {
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  color: #555d66; }

.table-of-contents__title {
  display: block;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600; }

.editor-template-validation-notice {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .editor-template-validation-notice .components-button {
    margin-left: 5px; }
