/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block UI.
 */
/**
 * Border radii.
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Focus styles.
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.block-editor-format-toolbar__image-container-content {
  display: flex; }
  .block-editor-format-toolbar__image-container-content .components-button {
    align-self: flex-end;
    height: 30px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 0 6px; }

.block-editor-format-toolbar__image-container-value {
  margin: 7px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  min-width: 150px;
  max-width: 500px; }
  .block-editor-format-toolbar__image-container-value.components-base-control .components-base-control__field {
    margin-bottom: 0; }
  .block-editor-format-toolbar__image-container-value.components-base-control .components-base-control__label {
    display: block; }

.block-editor-format-toolbar__link-container-content {
  display: flex; }

.block-editor-format-toolbar__link-container-value {
  margin: 7px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 150px;
  max-width: 500px; }
  .block-editor-format-toolbar__link-container-value.has-invalid-link {
    color: #d94f4f; }

.components-inline-color__indicator {
  position: absolute;
  background: #000;
  height: 3px;
  width: 20px;
  bottom: 6px;
  left: auto;
  right: auto;
  margin: 0 5px; }

.components-inline-color-popover .components-popover__content {
  padding: 20px 18px; }
  .components-inline-color-popover .components-popover__content .components-color-palette {
    margin-top: 0.6rem; }
  .components-inline-color-popover .components-popover__content .components-base-control__title {
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    color: #191e23; }
  .components-inline-color-popover .components-popover__content .component-color-indicator {
    vertical-align: text-bottom; }

.format-library-text-color-button {
  position: relative; }

.format-library-text-color-button__indicator {
  height: 4px;
  width: 20px;
  position: absolute;
  bottom: 10px;
  left: 8px; }
